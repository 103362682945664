import { FaCameraRetro } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { SlNote } from "react-icons/sl";

const skillsData = [
   {
      name: "AGRO ALIMENTACIÓN",
      icon: <FaCameraRetro className="text-4xl text-primary" />,
      link: "#",
      description:
         "Nuestra empresa tiene desarrollada una gestión de agroalimentación que involucra la planificación y superación de todas las etapas de la producción de alimentos, desde la siembra ocría hasta la distribución y consumo, incluyendo aspectos como la seguridad alimentaria y la sostenibilidad.",
      aosDelay: "0",
   },
   {
      name: "ASESORÍA",
      icon: <GiNotebook className="text-4xl text-primary" />,
      link: "#",
      description:
         "Asesoramos a empresas que se quieren venir a invertir  en el país, les ayudamos con el procedimiento legal para que sepan cómo llegar con sigo el proceso de implementación del país.",
      aosDelay: "300",
   },
   {
      name: "TRANSPORTE",
      icon: <SlNote className="text-4xl text-primary" />,
      link: "#",
      description:
         "Somos especialistas en alquiler de limosinas, vehículos de alta gama y deportivos exclusivos para dar ese toque prestigio y distinción a su viaje Nuestros coches de alta gama cuentan con la máxima calidad técnica y las últimas novedades tecnológicas.",
      aosDelay: "500",
   },
   {
      name: "PRODUCCIÓN GRÁFICA / COMUNICACIÓN",
      icon: <SlNote className="text-4xl text-primary" />,
      link: "#",
      description:
         "En nuestra empresa trabajamos al servicio del cliente poniendo a su disposición no solo un vehículo adaptado a sus necesidades, si no también a su economía, todo ello sin renunciar a los últimos modelos del mercado. Somos especialistas en el alquiler de limusinas, vehículos de alta gama, deportivos exclusivos para ese toque de prestigio y distinción as tu viaje.",
      aosDelay: "700",
   },
   {
      name: "TECNOLOGÍA",
      icon: <SlNote className="text-4xl text-primary" />,
      link: "#",
      description:
         "En el sector tecnológico nuestra empresa se centra en servicios de creación de páginas web y administración de las mismas y aplicaciones, compra y gestión de dominios.",
      aosDelay: "700",
   },
];
const Services = () => {
   return (
      <>
         <span id="#services"></span>
         <div className="bg-gray-100 dark:bg-black dark:text-white py-12 sm:grid sm:place-items-center">
            <div className="container">
               {/* Header */}
               <div className="pb-12 text-center space-y-3">
                  <h1
                     data-aos="fade-up"
                     className="text-3xl font-semibold sm:text-3xl text-violet-950 dark:text-primary"
                  >
                     Descubra nuestros servicios
                  </h1>
                  <p
                     data-aos="fade-up"
                     className="text-gray-600 dark:text-gray-400 text-sm"
                  >
                     Nuestra principal característica es que identificamos y
                     resolvemos las necesidades particulares de cada
                     organización,ofrecemos soluciones y oportunidades
                     personalizadas.
                  </p>
               </div>

               {/* services cards */}
               <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                  {skillsData.map((skill) => (
                     <div
                        key={skill.name}
                        data-aos="fade-up"
                        data-aos-delay={skill.aosDelay}
                        className="card space-y-3 sm:space-y-4 p-4"
                     >
                        <div>{skill.icon}</div>
                        <h1 className="text-lg font-semibold">{skill.name}</h1>
                        <p className="text-gray-600 dark:text-gray-400">
                           {skill.description}
                        </p>
                     </div>
                  ))}
               </div>

               {/* button */}
               <div
                  data-aos="fade-up"
                  data-aos-delay="900"
                  data-aos-offset="0"
                  className="text-center mt-4 sm:mt-8"
               >
                  <button className="primary-btn text-primary">
                     <a href="/servicios">
                        Saber más acerca de nuestros servicios
                     </a>
                  </button>
               </div>
            </div>
         </div>
      </>
   );
};

export default Services;
