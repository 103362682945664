import BlogCard from "./BlogCard";
import Img1 from "./../../img/comunicacion2.jpeg";
import Img2 from "./../../img/agroalimentacion1.jpeg";
import Img3 from "./../../img/transporte.jpeg";
import Img4 from "./../../img/fondo-de-home_.jpeg";
import Img5 from "./../../img/comunicacion1.jpeg";

const BlogsData = [
   {
      id: 1,
      image: Img1,
      title: "Asesoría",
   },
   {
      id: 2,
      image: Img2,
      title: "Agroalimentación",
   },
   {
      id: 3,
      image: Img3,
      title: "Transporte",
   },
   {
      id: 4,
      image: Img4,
      title: "Tecnología",
   },
   {
      id: 5,
      image: Img5,
      title: "Producción gráfica",
   },
];

const BlogsComp = () => {
   return (
      <>
         <div className="bg-gray-100 dark:bg-gray-900 dark:text-white py-10 pb-14">
            <section data-aos="fade-up" className="container ">
               <h1 className="my-8 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-semibold text-primary">
                  Nuestra Galería
               </h1>
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {BlogsData.map((item) => (
                     <BlogCard key={item.id} {...item} />
                  ))}
               </div>
            </section>
         </div>
      </>
   );
};

export default BlogsComp;
