const Nosotros = () => {
   return (
      <div className="bg-gray-100 dark:bg-gray-900 dark:text-white">
         <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
               <div className="flex flex-col text-center w-full mb-20">
                  <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-primary ">
                     Nuestra Empresa
                  </h1>
                  <p className="lg:w-2/3 mx-auto leading-relaxed dark:text-gray-400">
                     Somos una consultoría empresarial, servicio de
                     asesoramiento profesional en distintos campos, tenemos como
                     propósito presentar oportunidades de inversion,acompanar y
                     mejorar las prácticas realizadas por empresas y
                     organizaciones en Guiea Ecuatorial.
                  </p>
                  <br />
                  <p className="lg:w-2/3 mx-auto leading-relaxed dark:text-gray-400">
                     Nuestra principal característica es que identificamos y
                     resolvemos las necesidades particulares de cada
                     organización,ofrecemos soluciones y oportunidades
                     personalizadas
                  </p>
               </div>
            </div>
         </section>
         <section className="dark:text-gray-400">
            <div className="container px-5 py-24 mx-auto">
               <section className="body-font">
                  <div className="container px-5 py-24 mx-auto">
                     <div className="flex flex-col text-center w-full mb-20">
                        <h1 className="text-2xl font-medium title-font mb-4 text-primary">
                           Conoce a nuestro equipo
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed dark:text-gray-400">
                           Somos un equipo de profesionales que trabajamos para
                           maximizar los beneficios de nuestros clietes.
                        </p>
                     </div>
                     <div className="flex flex-wrap -m-4">
                        <div className="p-4 lg:w-1/4 md:w-1/2">
                           <div className="h-full flex flex-col items-center text-center">
                              <img
                                 alt="team"
                                 className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4"
                                 src="https://dummyimage.com/200x200"
                              />
                              <div className="w-full">
                                 <h2 className="title-font font-medium text-lg text-primary">
                                    Nombre
                                 </h2>
                                 <h3 className="text-secondary-200 mb-3">
                                    Técnico
                                 </h3>
                                 <p className="mb-4 dark:text-gray-400">
                                    Técnico, responsable de la actividad...
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div className="p-4 lg:w-1/4 md:w-1/2">
                           <div className="h-full flex flex-col items-center text-center">
                              <img
                                 alt="team"
                                 className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4"
                                 src="https://dummyimage.com/201x201"
                              />
                              <div className="w-full">
                                 <h2 className="title-font font-medium text-lg text-primary">
                                    Nombre
                                 </h2>
                                 <h3 className="text-secondary-200 mb-3">
                                    Director General
                                 </h3>
                                 <p className="mb-4 dark:text-gray-400">
                                    Dirige la empresa.
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div className="p-4 lg:w-1/4 md:w-1/2">
                           <div className="h-full flex flex-col items-center text-center">
                              <img
                                 alt="team"
                                 className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4"
                                 src="https://dummyimage.com/202x202"
                              />
                              <div className="w-full">
                                 <h2 className="title-font font-medium text-lg text-primary">
                                    Nombre
                                 </h2>
                                 <h3 className="text-secondary-200 mb-3">
                                    Secretaria
                                 </h3>
                                 <p className="mb-4 dark:text-gray-400">
                                    Especialista en el secretariado.
                                 </p>
                              </div>
                           </div>
                        </div>
                        <div className="p-4 lg:w-1/4 md:w-1/2">
                           <div className="h-full flex flex-col items-center text-center">
                              <img
                                 alt="team"
                                 className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4"
                                 src="https://dummyimage.com/203x203"
                              />
                              <div className="w-full">
                                 <h2 className="title-font font-medium text-lg text-primary">
                                    Nombre
                                 </h2>
                                 <h3 className="text-secondary-200 mb-3">
                                    Marketing
                                 </h3>
                                 <p className="mb-4 dark:text-gray-400">
                                    Especialista en Marketing Digital.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </section>
      </div>
   );
};

export default Nosotros;
