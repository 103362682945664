export default function Contact() {
   return (
      <section className="bg-gray-100 dark:bg-gray-900 dark:text-white">
         <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-col text-center w-full mb-12">
               <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-secondary-200">
                  Ponte en Contacto con Nosotros
               </h1>
               <p className="lg:w-2/3 mx-auto leading-relaxed dark:text-gray-400">
                  Si quieres saber más acerca de todo lo que ofrecemos, puedes
                  ponerte en contacto con nosotros
               </p>
            </div>
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
               <div className="flex flex-wrap -m-2">
                  <div className="p-2 w-1/2">
                     <div className="relative">
                        <label
                           for="name"
                           className="leading-7 text-sm dark:text-gray-400"
                        >
                           Nombre
                        </label>
                        <input
                           type="text"
                           id="name"
                           name="name"
                           className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                  </div>
                  <div className="p-2 w-1/2">
                     <div className="relative">
                        <label
                           for="email"
                           className="leading-7 text-sm dark:text-gray-400"
                        >
                           Correo
                        </label>
                        <input
                           type="email"
                           id="email"
                           name="email"
                           className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                  </div>
                  <div className="p-2 w-full">
                     <div className="relative">
                        <label
                           for="message"
                           className="leading-7 text-sm dark:text-gray-400"
                        >
                           Mensaje
                        </label>
                        <textarea
                           id="message"
                           name="message"
                           className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                        ></textarea>
                     </div>
                  </div>
                  <div className="p-2 w-full">
                     <button className="flex mx-auto text-primary bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                        Enviar
                     </button>
                  </div>
                  <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                     <p className="px-5">
                        Correo:
                        <a className="text-primary">
                           contacto@jobserviciosgroup.com
                        </a>
                     </p>
                     <p className="px-5 ">
                        Tel:
                        <span className="text-primary">555715727</span>
                     </p>
                     <p className="leading-normal my-5">
                        España: Calle Alicante 6, local B - Torrejon de Ardoz
                        (Madrid)
                     </p>
                     <p className="leading-normal my-5">
                        GE: Carretera de Sera, Malabo
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}
