import yellowCar from "./../../img/logo_jobservicios.png";

const Hero = () => {
   return (
      <div className="dark:bg-gray-950 dark:text-white bg-[url('https://images.unsplash.com/photo-1521791136064-7986c2920216?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-no-repeat bg-cover">
         <div className="container min-h-[680px] flex mt-5 sm:mt-0">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 place-items-center">
               {/* Image section */}
               <div data-aos="zoom-in" className="order-1 sm:order-2 relative">
                  <img
                     src={yellowCar}
                     alt=""
                     className="w-full sm:max-w-[280px] md:max-w-[430px] rounded-sm"
                  />
                  <div
                     data-aos="slide-right"
                     className="absolute -bottom-5 -right-8 px-4 py-2 rounded-xl bg-white dark:bg-gray-900 dark:text-white shadow-md"
                  ></div>
               </div>

               {/* Text section */}
               <div className="space-y-5 order-2 sm:order-1 xl:pr-40 ">
                  <h1
                     data-aos="fade-up"
                     className="text-4xl sm:text-5xl font-semibold"
                     style={{ lineHeight: 1.2 }}
                  >
                     <span className="text-primary text-center">
                        Somos tu calidad por excelencia
                     </span>
                  </h1>
                  <p data-aos="fade-up" data-aos-delay="300">
                     Somos una empresa que presta servicios y soluciones en
                     distintos campos.
                  </p>
                  <p data-aos="fade-up" data-aos-delay="300">
                     Te brindamos la solución para la eliminación de los
                     obstáculos que impiden desarrollar tu negocio, mejor
                     eficiencia en tu sector, dotándote de las mejores
                     herramientas, comunicación y fortaleza para cumplir los
                     objetivos marcados para tu inversión o espacio. LLevándote
                     de la mano con la mayor y mejor por excelencia.
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Hero;
