import { MenuLinks } from "./Navbar";

const ResponsiveMenu = ({ showMenu }) => {
   console.log("showMenu", showMenu);
   return (
      <div
         className={`${
            showMenu ? "left-0" : "-left-[100%]"
         } fixed bottom-0 top-0 z-20 flex h-screen w-[75%] flex-col justify-between bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-16 text-black transition-all duration-200 md:hidden rounded-r-xl shadow-md`}
      >
         <div className="card">
            <nav className="mt-12">
               <ul className="space-y-4 text-xl">
                  {MenuLinks.map((data) => (
                     <li key={data.name}>
                        <a href={data.link} className="mb-5 inline-block">
                           {data.name}
                        </a>
                     </li>
                  ))}
               </ul>
            </nav>
         </div>
         <div className="footer">
            <h1>
               Built with ❤ by{" "}
               <a href="https://www.scorpionfreelancers.net">
                  Scorpion Freelancers
               </a>{" "}
            </h1>
         </div>
      </div>
   );
};

export default ResponsiveMenu;
