const Servicios = () => {
   return (
      <div className="bg-gray-100 dark:bg-gray-900 dark:text-white">
         <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto">
               <div class="flex flex-col text-center w-full mb-20">
                  <h2 class="text-xs text-primary tracking-widest font-medium title-font mb-1">
                     DESCUBRE TODOS NUESTROS SERVICIOS
                  </h2>
                  <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-secondary-200">
                     Ofrecemos un abanico amplio de servicios
                  </h1>
                  <p class="lg:w-2/3 mx-auto leading-relaxed text-gray-600 dark:text-gray-400">
                     Nuestra principal característica es que identificamos y
                     resolvemos las necesidades particulares de cada
                     organización,ofrecemos soluciones y oportunidades
                     personalizadas.
                  </p>
               </div>
               <div class="flex flex-wrap">
                  <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                     <h2 class="text-lg sm:text-xl text-primary font-medium title-font mb-2">
                        Agroalimentación
                     </h2>
                     <p class="leading-relaxed text-gray-600 dark:text-gray-400 mb-4">
                        Nos dedicamos a los productos naturales siempre y cuando
                        respetando el medio ambiente, desde la plantación hasta
                        las pequeñas transformaciones.
                     </p>
                  </div>
                  <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                     <h2 class="text-lg sm:text-xl text-primary font-medium title-font mb-2">
                        Producción Gráfica / Comunicación
                     </h2>
                     <p class="leading-relaxed text-gray-600 dark:text-gray-400 mb-4">
                        Ofrecemos una gama de servicios transformando las ideas
                        de nuestros clientes en un objetivo cumplido para ellos,
                        dando visibilidad a los negocios, eventos con los
                        servicios de impresión de todo tipo y personificación de
                        cualquier material que desea el cliente.
                     </p>
                  </div>
                  <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                     <h2 class="text-lg sm:text-xl text-primary font-medium title-font mb-2">
                        Asesoría
                     </h2>
                     <p class="leading-relaxed text-gray-600 dark:text-gray-400 mb-4">
                        Asesoramos a las empresas en temas fiscales como pueden
                        implementar sus negocios en el país.
                     </p>
                  </div>
               </div>
               <div class="flex flex-wrap">
                  <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                     <h2 class="text-lg sm:text-xl text-primary font-medium title-font mb-2">
                        Transporte
                     </h2>
                     <p class="leading-relaxed text-gray-600 dark:text-gray-400 mb-4">
                        Especializados en alquiler de vehículos de alta gama,
                        transporte público, vehículos deportivos etc.
                     </p>
                  </div>

                  <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                     <h2 class="text-lg sm:text-xl text-primary font-medium title-font mb-2">
                        Tecnología
                     </h2>
                     <p class="leading-relaxed text-gray-600 dark:text-gray-400 mb-4">
                        Aportamos a nuestra marca los tick de las últimas
                        tendencias de la tecnología garantizando mejor resultado
                        en calidad por excelencia.
                     </p>
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
};

export default Servicios;
