import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// Component import
import Hero from "./Hero.jsx";
import Services from "./Services.jsx";
import BlogsComp from "./BlogsComp.jsx";

const Home = () => {
   useEffect(() => {
      AOS.init({
         offset: 100,
         duration: 800,
         easing: "ease-in",
         delay: 100,
      });
      AOS.refresh();
   }, []);

   return (
      <div className="bg-white dark:bg-black dark:text-white text-black overflow-x-hidden">
         <Hero />
         <Services />
         <BlogsComp />
      </div>
   );
};

export default Home;
