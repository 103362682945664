import { FaInstagram, FaTiktok } from "react-icons/fa";
import logo from "./../../img/logo_jobservicios.png";
import { Link } from "react-router-dom";

const Footer = () => {
   return (
      <div className="dark:bg-gray-950 dark:text-white">
         {/* foorter  */}
         <div>
            <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
               <Link
                  to="/"
                  className="flex title-font font-medium items-center md:justify-start justify-center text-pramary"
               >
                  <img src={logo} className="h-10 rounded-sm" />
                  <span className="ml-3 text-xl">Job Servicios Group</span>
               </Link>
               <p className="text-sm text-primary sm:ml-6 sm:mt-0 mt-4">
                  © 2024 Job Servicios Group
               </p>
               <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start gap-2">
                  <a
                     target="_blank"
                     href="https://www.instagram.com/job_servicios6/"
                  >
                     <FaInstagram className="text-2xl hover:text-primary duration-300" />
                  </a>
                  <a
                     target="_blank"
                     href="https://www.tiktok.com/@job_servicios/"
                  >
                     <FaTiktok className="text-2xl hover:text-primary duration-300" />
                  </a>
               </span>
            </div>
         </div>
      </div>
   );
};

export default Footer;
